@use '@angular/material' as mat;
@import '@angular/material/core/theming/theming';

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$mat-primary: (
    700: #001b3f,
    500: #263043,
    100: #002f65,
    50: #003a79,
    // For slide toggle,
    contrast:
        (
            700: $light-primary-text,
            500: $light-primary-text,
            50: $dark-primary-text,
        ),
);

$mat-accent: (
    700: #1e5797,
    500: #376fd0,
    100: #82aaff,
    50: #aec6ff,
    // For slide toggle,
    contrast:
        (
            700: $light-primary-text,
            500: $light-primary-text,
            50: $dark-primary-text,
        ),
);
// Warning Color
$mat-warn: (
    700: #a6292b,
    500: #dd393c,
    100: #fa7b7d,
    50: #df8a8b,
    // For slide toggle,
    contrast:
        (
            700: $light-primary-text,
            500: $light-primary-text,
            50: $dark-primary-text,
        ),
);
$fontConfig: (
    display-4:
        mat.m2-define-typography-level(
            112px,
            112px,
            300,
            'apple-system, sans-serif',
            -0.0134em
        ),
    display-3:
        mat.m2-define-typography-level(
            56px,
            56px,
            400,
            'apple-system, sans-serif',
            -0.0089em
        ),
    display-2:
        mat.m2-define-typography-level(
            45px,
            48px,
            400,
            'apple-system, sans-serif',
            0em
        ),
    display-1:
        mat.m2-define-typography-level(
            34px,
            40px,
            400,
            'apple-system, sans-serif',
            0.0074em
        ),
    headline:
        mat.m2-define-typography-level(
            24px,
            32px,
            400,
            'apple-system, sans-serif',
            0em
        ),
    title:
        mat.m2-define-typography-level(
            20px,
            32px,
            500,
            'apple-system, sans-serif',
            0.0075em
        ),
    subheading-2:
        mat.m2-define-typography-level(
            16px,
            28px,
            400,
            'apple-system, sans-serif',
            0.0094em
        ),
    subheading-1:
        mat.m2-define-typography-level(
            15px,
            24px,
            500,
            'apple-system, sans-serif',
            0.0067em
        ),
    body-2:
        mat.m2-define-typography-level(
            14px,
            24px,
            500,
            'apple-system, sans-serif',
            0.0179em
        ),
    body-1:
        mat.m2-define-typography-level(
            14px,
            20px,
            400,
            'apple-system, sans-serif',
            0.0179em
        ),
    button:
        mat.m2-define-typography-level(
            14px,
            14px,
            500,
            'apple-system, sans-serif',
            0.0893em
        ),
    caption:
        mat.m2-define-typography-level(
            12px,
            20px,
            400,
            'apple-system, sans-serif',
            0.0333em
        ),
    input:
        mat.m2-define-typography-level(
            inherit,
            1.125,
            500,
            'apple-system, sans-serif',
            1px
        ),
);

$so-primary: mat.m2-define-palette($mat-primary, 500);
$so-accent: mat.m2-define-palette($mat-accent, 500, 100, 700);
$so-warn: mat.m2-define-palette($mat-warn, 500, 100, 700);
// $so-typography:  mat.define-typography-config($font-family: 'apple-system, sans-serif',);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $so-primary,
            accent: $so-accent,
            warn: $so-warn,
        ),
        typography: $fontConfig,
        density: 0,
    )
);

@include mat.all-component-themes($my-theme);
// @include mat.core-theme($my-theme);
// @include mat.button-theme($my-theme);

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';
    .mat-badge-content {
        font-family: 'apple-system, sans-serif';
    }
}
