/* ------------- Global Variables ------------- */
@use './assets/styles/_global-style-variables.scss' as v;

/* ------------- Ag Grid ------------- */
@use 'node_modules/ag-grid-community/styles/_index.scss' as ag;

/* --- Material UI -------------------------------------------------*/
@import 'node_modules/@angular/material/core/theming/theming';

/* --- PrimeNG UI -------------------------------------------------*/
@import 'node_modules/primeicons/primeicons.css';
@import 'node_modules/primeng/resources/themes/saga-blue/theme.css';
@import 'node_modules/primeng/resources/primeng.min.css';
@import 'node_modules/primeflex/primeflex.css';

/* --- Layout -------------------------------------------------*/

body {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    color: v.$text-color-primary;
    // font-family: 'Roboto', sans-serif !important;
    font-family: apple-system, 'Roboto', sans-serif !important;
    line-height: inherit !important;
    min-height: 100vh;
    font-size: 0.76rem;
    overflow: hidden;
    -ms-overflow-style: hidden !important;
    // background-color: v.$gs-background-main !important;
    // font-family: apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,!important;
}
html,
body {
    height: 100%;
}
p {
    color: v.$text-color-primary;
}

.height-inherit {
    height: inherit !important;
}
.h-100 {
    height: 100% !important;
}
.w-100 {
    width: 100% !important;
}
.m-0 {
    margin: 0px !important;
}
.m-t-10 {
    margin-top: 10px;
}
.m-r-10 {
    margin-right: 10px;
}
.m-b-10 {
    margin-bottom: 10px;
}
.p-0 {
    padding: 0px;
}
.flex-center-all {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* --- Typography -------------------------------------------------*/
.bold {
    font-weight: bold;
}
.text-lte {
    color: v.$text-color-lte;
}
.text-accent {
    color: v.$secondary;
}
.text-warn {
    color: v.$accent-warning;
}
.material-icons {
    font-size: 20px;
}
.text-center {
    text-align: center !important;
}
.m-letter-spacing {
    letter-spacing: 0.6px;
}
// ---> Lists
ul {
    list-style-type: none;
    margin-top: 4px;
}
.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    // display: inline-block;
    width: inherit;
    text-decoration: none;
    cursor: pointer;
}
.mdc-list-item__primary-text,
.mdc-text-field__input,
.mdc-form-field,
.mat-mdc-form-field {
    color: v.$text-color-primary !important;
}
.mat-mdc-menu-item {
    &[color='accent'] > .mdc-list-item__primary-text {
        color: v.$secondary !important;
    }
    &[color='warn'] > .mdc-list-item__primary-text {
        color: v.$accent-warning !important;
    }
}

/* --- AG Grid Themes  -------------------------------------------------*/

@include ag.grid-styles(
    (
        theme: alpine,
        alpine-active-color: v.$primary-main,
        icon-font-family: agGridMaterial,
        --ag-checkbox-checked-color: v.$secondary,
        --ag-secondary-foreground-color: v.$text-grid-primary,
        --ag-data-color: v.$text-grid-primary // input-focus-border-color: none,
        // input-focus-box-shadow: 1px 2px 6px -2px v.$primary-main,,,,,,,,,,,,,,,
    )
);
.ag-theme-alpine {
    --ag-grid-size: 5px;
    --ag-list-item-height: 24px;

    .ag-header-icon,
    .ag-header-icon:hover,
    .ag-header-cell-sortable {
        color: white;
    }

    .ag-column-drop-list.ag-column-drop-horizontal-list {
        overflow: auto;
    }
    .ag-column-drop-horizontal,
    .ag-side-bar-right,
    .ag-side-bar-left {
        background-color: v.$background-headers-lte;
    }
    .ag-side-bar-left {
        border-radius: 4px 0px 0px 0px;
    }
    .ag-side-bar-right {
        border-radius: 0px 4px 0px 0px;
    }
    .ag-column-drop-horizontal-icon,
    .ag-column-drop-horizontal-cell-separator,
    .ag-side-button-label,
    .ag-side-button-icon-wrapper,
    .ag-column-drop-horizontal-empty-message {
        color: #eeeeee;
        font-weight: bold;
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
    }
    .ag-icon-tree-open {
        color: v.$secondary;
    }
    .ag-side-button-icon-wrapper {
        transform: rotate(-90deg);
    }
    .ag-filter-toolpanel-group {
        border-top: none;
    }
    .ag-tool-panel-wrapper {
        ManageDistroChanges {
            width: inherit;
            div > .mat-accordion .mat-expansion-panel {
                box-shadow: none;
            }
        }
    }
    .ag-popup-child {
        z-index: 2000;
    }
    .ag-ltr .ag-side-bar-left .ag-tool-panel-wrapper {
        border: 1px solid v.$background-headers-lte;
        background-color: white;
    }
    .ag-filter-toolpanel-group-title-bar {
        margin: 0px 8px 8px 8px;
        border-radius: 4px;
        color: v.$background-headers-lte;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
    .ag-filter-toolpanel-instance-header {
        span.ag-header-cell-text {
            color: v.$primary-main;
        }
    }
    // .ag-row-odd:not(:hover) {
    //     // background-color: #f0f8fbf0;
    //     background-color: #ecf3fb;
    // }
    .ag-row-odd {
        background-color: #ecf3fb;
    }
    .edited-row {
        font-weight: bold;
        color: v.$primary-main;
    }
    .parent-row-warning {
        color: v.$accent-warning;
        font-weight: bold;
        background-color: #ffdfdf;
        border-bottom: 2px solid v.$accent-warning;
    }
    .parent-row-alert {
        // background-color: #fef1da;
        // border-bottom: 2px solid v.$accent-alert;
        border-bottom: 2px solid v.$accent-warning;
    }

    .ag-header-viewport,
    .ag-header {
        background-color: v.$background-headers-lte;
    }
    .ag-header {
        border-radius: 4px 4px 0px 0px;
    }
    &[tool-panel] {
        > * .ag-header {
            border-radius: 0px 4px 0px 0px;
        }
    }

    .ag-header-group-text,
    .ag-header-cell-text,
    .ag-header-icon {
        color: white;
        text-transform: uppercase;
    }
    .ag-header-group-text {
        width: 100%;
        text-align: center;
    }
    .ag-header-cell-text,
    .ag-header-icon {
        cursor: pointer;
    }
    // AG Grid main grid styling
    &.ag-grid-container {
        width: 100%;
        height: inherit;
        padding-bottom: 2px;
    }

    &.ag-modal-grid-large {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
    .ag-row-pinned {
        font-weight: Bold;
        border-bottom: 1px solid v.$secondary;
        color: v.$secondary;
    }
    .ag-root-wrapper {
        border: none;
    }
    .ag-status-bar {
        border-top: 2px solid v.$background-headers-lte;
        display: flex;
        justify-content: flex-end;
        padding: 0px 10px;
        > * span {
            color: v.$text-grid-primary;
        }
    }
    .ag-row-selected {
        background-color: #e7f2ff;
    }
    // .ag-row-hover {
    // }
    .ag-full-width-row {
        height: auto !important;
    }
    .highlight-cell {
        background-color: #faf29c;
    }
    .disabled-cell,
    .disabled-cell > child-cell > span > button {
        color: v.$dissabled-text-color !important;
        cursor: not-allowed;
    }
    .footer-cell {
        color: v.$text-color-primary;
        font-weight: normal;
        > * span > p {
            color: v.$text-color-primary;
            font-weight: normal;
        }
    }
    .cell-warning {
        color: v.$accent-warning;
        > * span > p {
            color: v.$accent-warning;
        }
    }
    .cell-text-bold {
        font-weight: bold;
        > * span > p {
            font-weight: bold;
        }
    }
    .cell-alert {
        color: v.$accent-alert;
        > * span > p {
            color: v.$accent-alert;
        }
    }

    .ag-virtual-list-container {
        overflow: visible;
        > div > div > div.ag-set-filter-item-checkbox {
            overflow: visible;
        }
        > div > div > div > div.ag-label-ellipsis {
            overflow: visible;
        }
    }
    .ag-horizontal-left-spacer,
    .ag-horizontal-right-spacer {
        overflow-x: auto !important;
    }
    .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
        .ag-horizontal-left-spacer:not(.ag-scroller-corner),
    .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
        .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
        border: none !important;
    }
    .ag-virtual-list-viewport {
        padding-bottom: 5px;
    }
}

.ag-details-row {
    .ag-header-viewport,
    .ag-pinned-right-header,
    .ag-pinned-left-header {
        background-color: v.$primary-main;
    }
}

.main-ag-grid-container {
    display: flex;
    width: 100%;
    column-gap: 10px;
    // height: calc(100% - 48px);
    height: calc(100% - 2px);
    // padding: 2px;
    &.flex-column {
        flex-direction: column;
    }
}
.main-ag-grid-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid lightgrey;
    min-height: 49px;
}

.grid-title {
    color: white;
    background-color: v.$background-headers-lte;
    padding: 0px 10px;
    border-radius: 0 4px 4px 0px;
    letter-spacing: 0.6px;
    > h3 {
        margin: 0px;
    }
}
.grid-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 5px 10px 0px;
}

.grid-tool-panel-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    flex: 2;
}

/* --- Angular Material Forms -------------------------------------------------*/
.mat-mdc-form-field-error-wrapper {
    z-index: 2000;
    padding: 0px !important;
}
.mat-mdc-form-field-error {
    padding: 0px 5px;
    font-weight: bold;
}
mat-radio-button > div > label {
    color: v.$text-color-primary;
}
// .mdc-text-field--filled:not(.mdc-text-field--disabled){
//     background-color: transparent;
// }
// .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
//     border-bottom-color: v.$background-headers-lte;
// }
/* --- Mat Stepper---------------------------------------------------------*/
.mat-step-header {
    .mat-step-icon-selected {
        background-color: v.$quaternary;
        box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024,
            0 1px 3px #0000001f;
    }
    .mat-step-label.mat-step-label-active {
        color: v.$text-color-primary;
    }
    .mat-step-label.mat-step-label-active.mat-step-label-selected {
        color: v.$quaternary;
    }
    .mat-step-icon-state-edit {
        background-color: v.$secondary;
    }
}
.mat-step-sub-label-error {
    color: v.$accent-warning;
}

/* --- Mat Tab Group / Product Hierarchy tree ---------------------------------------------------------*/
.mat-mdc-tab-header {
    border-bottom: 1px solid lightgrey;
}

mat-tab-group,
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body {
    height: 100%;
}

app-product-hierarchy {
    flex: 2;
    height: inherit;
    overflow: hidden;
    > div {
        overflow: hidden;
        height: inherit;
        > p-tree {
            height: 100%;
            > div {
                height: inherit;
                align-content: flex-start !important;
            }
        }
    }
}
.mat-mdc-tab-body-wrapper {
    overflow: hidden;
}
.mat-mdc-tab-body-content {
    height: inherit;
    > div {
        overflow: hidden;
        height: inherit;
    }
}

// .mat-mdc-tab-body-wrapper {
//     height: 100%;
// }
/* --- Standard Buttons -------------------------------------------------*/
.button-tool-panel {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
}

/* --- Split Buttons -------------------------------------------------*/
#split-button {
    border-radius: 0px 4px 4px 0px;
    min-width: 20px;
    border-left: 0px solid white;
    [disabled='false'] {
        background-color: #376fd1;
    }
}
.primary-split-button {
    border-radius: 4px 0px 0px 4px !important;
}

/* --- Prime NG Temp -------------------------------------------------*/
.pi {
    font-size: 14px;
}

.p-overlaypanel .p-overlaypanel-close {
    background-color: v.$secondary;
}
.overlay-panel-footer-container {
    width: 100%;
    text-align: right;
    border-top: 1px solid lightgrey;
    padding-top: 10px;
    margin-top: 10px;
}
// ::ng-deep.mdc-tab-indicator--active {
//     > span.mdc-tab__content {
//         >.mdc-tab__text-label {
//            color: v.$quaternary
//         }
//     }

//     >span > .mdc-tab-indicator__content--underline {
//         border-color: v.$quaternary
//     }
// }
/* --- Mat Menu Styling -------------------------------------------------*/
.mat-mdc-menu-content {
    flex-direction: column;
    padding: 10px;
    column-gap: 5px;
    row-gap: 5px;
    display: flex;
}
// Minimize the menu button height
.mat-mdc-menu-item {
    min-height: 30px !important;
}
// Delete button color
.mat-mdc-menu-item.menu-panel-warn-btn .mdc-list-item__primary-text {
    color: v.$accent-warning;
}

// All menu buttons color
.mat-mdc-menu-item.menu-panel-accent-btn .mdc-list-item__primary-text {
    color: v.$secondary;
}
.mat-mdc-menu-item.menu-panel-accent-btn:disabled {
    cursor: not-allowed;
}

/* --- Prime NG Checkbox -------------------------------------------------*/
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: v.$secondary;
    background: v.$secondary;
    width: 18px;
    height: 18px;
    font-weight: 13px;
    border-radius: 2px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-weight: bold;
}
/* --- Prime NG Picklist -------------------------------------------------*/
.p-picklist.p-picklist-striped
    .p-picklist-list
    .p-picklist-item:nth-child(even) {
    background: #ecf3fb;
}
.p-picklist .p-picklist-buttons .p-button {
    box-shadow: var(
        --mdc-protected-button-container-elevation,
        0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12)
    );
}
/* --- Modal Formatting -------------------------------------------------*/
.p-overlaypanel:before,
.p-overlaypanel:after {
    display: none;
}
.p-overlaypanel {
    z-index: 999 !important;
}
.p-dialog-mask.p-component-overlay {
    z-index: 999 !important;
}

.p-dialog-mask {
    background-color: rgba(0, 0, 0, 0.4) !important;
}
.p-dialog {
    padding: 14px 14px 7px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    background-color: #ffffff;
    color: v.$text-color-primary;
    border: 1px solid #c8c8c8;
    min-width: 400px;
    // width: 50vw;
    width: fit-content;
    margin: 20px;
    &.small-modal {
        width: 40%;
        height: 55%;
    }
    &.medium-short-modal {
        width: 70%;
        height: 60%;
    }
    &.medium-modal {
        width: 70%;
        height: 70%;
    }
    &.large-modal {
        width: 100%;
        height: 100%;
    }
    .p-dialog-content {
        padding: 0px;
        height: 100%;
        display: flex;
        width: 100%;
    }
    .p-dialog-content > * {
        display: flex;
        flex-direction: column;
        height: inherit;
        overflow: hidden;
        width: inherit;
    }
    .p-dialog-content > * > .modal-content {
        max-width: 80vw;
        display: flex;
        column-gap: 20px;
        row-gap: 20px;
        flex: 2;
        flex-direction: column;
        overflow: auto;
        &[flexDirection='row'] {
            flex-direction: row;
        }
        // &[size='small'] {
        // }
        // &[size='medium'] {
        // }
        &[size='large'] {
            max-width: 100%;
        }
        &[loading='true'] {
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
        }
    }
}
.close-modal-btn {
    cursor: pointer;
    background-color: transparent;
    border: none;
    text-align: right;
    padding: 0px;
    color: grey;
    font-weight: bold;
    pointer-events: visible;
}

.close-modal-btn:hover {
    color: v.$secondary !important;
    text-shadow: 2px 2px 3px rgba(122, 122, 122, 0.86);
}
.modal-content,
.modal-content-small {
    // min-height: 200px;
    height: 100%;
    max-height: 80vh;
    overflow-y: auto;
    padding: 10px;
}

.modal-content-small[loading='true'],
.modal-content-large[loading='true'] {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
}

// .modal-content-small {
//     max-width: 80vw;
//     width: 100%;
// }
.modal-content-large {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 162px);
    padding: 10px;
}
.modal-wrapper-medium {
    width: 80%;
    min-height: 60%;
}
// Modal settings that include product hierarchies
.hierarchy-modal {
    overflow: hidden;
    height: inherit;
    min-width: 80vw;
    > div {
        height: inherit;
        > app-distro-copy-new-product-modal
            > div
            > section
            > div.content-wrapper
            > app-product-hierarchy
            > div
            > p-tree
            > div.p-tree
            > div.p-tree-wrapper {
            max-height: calc(100% - 86px) !important;
        }
    }
}

.primary-section-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.section-body-container {
    padding: 0px 10px;
    flex-direction: column;
    display: flex;
    overflow: auto;
}
.selection-summary-text-container {
    display: flex;
    flex-direction: column;
    border-top: 2px solid v.$background-headers-lte;
    padding-top: 10px;
    margin: 0px 10px;
    > span {
        display: flex;
        column-gap: 5px;
        > p {
            font-size: 14px;
            &.summary-title {
                color: v.$background-headers-lte;
            }
        }
    }
}

/* --- Angular Material Custom Tooltip ---------------------------------------------------------*/
.mdc-tooltip__surface {
    background-color: white !important;
    color: v.$quaternary !important;
    text-transform: none !important;
}
.mdc-tooltip {
    position: relative;
    overflow: initial;
    border: 2px solid v.$quaternary;
    box-shadow: v.$box-shadow-1;
    border-radius: 4px;
    text-transform: capitalize;
    &::after {
        width: 0;
        height: 0;
        content: '';
        position: absolute;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-bottom: 0.5rem solid v.$quaternary;
    }
    &.below {
        &:after {
            top: -0.5rem;
            right: calc(50% - 0.5rem);
            transform: rotate(0);
        }
    }

    &.above {
        overflow: initial;
        &:after {
            bottom: -0.5rem;
            right: calc(50% - 0.5rem);
            transform: rotate(180deg);
        }
    }

    &.right {
        overflow: initial;
        &:after {
            left: -0.75rem;
            top: calc(50% - 0.25rem);
            transform: rotate(270deg);
        }
    }

    &.left {
        overflow: initial;
        &:after {
            right: -0.75rem;
            top: calc(50% - 0.25rem);
            transform: rotate(90deg);
        }
    }

    &.lastColumn {
        &:after {
            top: -0.5rem;
            right: 2rem;
        }
    }

    &.reviewOrdersSettings {
        &:after {
            top: -0.5rem;
            right: calc(50% - 0.5rem);
        }
    }
}
/* --- Mat Expansion Panel ---------------------------------------------------------*/
.mat-expansion-panel-body {
    padding: 0px 24px !important;
}

// .mat-drawer-container {
//     color: v.$text-color-primary;
// }
/* --- Display Scroll ---------------------------------------------------------*/
.p-scroller {
    height: calc(100% - 89px) !important;
}

body ::-webkit-scrollbar-track {
    border-radius: 5px !important;
}
.main-content-container::-webkit-scrollbar,
.selected-nodes-wrapper > .inputs-container::-webkit-scrollbar,
.mat-drawer-inner-container > div::-webkit-scrollbar,
.form-content-container::-webkit-scrollbar,
.p-tree-wrapper::-webkit-scrollbar,
.dashboard-wrapper::-webkit-scrollbar,
.custom-vertical-scroll::-webkit-scrollbar,
#mainBarChart > div::-webkit-scrollbar,
.ag-virtual-list-viewport::-webkit-scrollbar,
.ag-virtual-list-container::-webkit-scrollbar,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar,
.p-listbox-list-wrapper::-webkit-scrollbar,
.mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar,
.p-picklist-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
}
// Primary
.main-content-container::-webkit-scrollbar-thumb,
.selected-nodes-wrapper > .inputs-container::-webkit-scrollbar-thumb,
.form-content-container::-webkit-scrollbar-thumb,
.p-tree-wrapper::-webkit-scrollbar-thumb,
.dashboard-wrapper::-webkit-scrollbar-thumb,
.custom-vertical-scroll::-webkit-scrollbar-thumb,
#mainBarChart > div::-webkit-scrollbar-thumb,
.ag-virtual-list-viewport::-webkit-scrollbar-thumb,
.ag-virtual-list-container::-webkit-scrollbar-thumb,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb,
.p-listbox-list-wrapper::-webkit-scrollbar-thumb,
.mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-thumb,
.p-picklist-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: v.$primary-main !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
// Nav Bar scroll color
.mat-drawer-inner-container > div::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: v.$nav-text-category !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.main-content-container::-webkit-scrollbar-track,
.selected-nodes-wrapper > .inputs-container::-webkit-scrollbar-track,
.mat-drawer-inner-container > div::-webkit-scrollbar-track,
.form-content-container::-webkit-scrollbar-track,
.p-tree-wrapper::-webkit-scrollbar-track,
.dashboard-wrapper::-webkit-scrollbar-track,
.custom-vertical-scroll::-webkit-scrollbar-track,
#mainBarChart > div::-webkit-scrollbar-track,
.ag-virtual-list-viewport::-webkit-scrollbar-track,
.ag-virtual-list-container::-webkit-scrollbar-track,
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.ag-body-horizontal-right-spacer::-webkit-scrollbar-track,
.ag-body-horizontal-left-spacer::-webkit-scrollbar-track,
.modal-content::-webkit-scrollbar-track,
.p-listbox-list-wrapper::-webkit-scrollbar-track,
.mdc-menu-surface.mat-mdc-autocomplete-panel::-webkit-scrollbar-track,
.p-picklist-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.ag-body-horizontal-left-spacer::-webkit-scrollbar,
.ag-body-horizontal-right-spacer::-webkit-scrollbar,
.modal-content::-webkit-scrollbar {
    -webkit-appearance: none;
    // width: 7px;
    height: 7px;
}
.ag-column-drop-list.ag-column-drop-horizontal-list::-webkit-scrollbar-thumb,
.ag-body-horizontal-left-spacer::-webkit-scrollbar-thumb,
.ag-body-horizontal-right-spacer::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb,
.modal-content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: v.$primary-main !important;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ag-body-viewport {
    position: relative;
    overflow: auto !important;
}

.ag-center-cols-viewport {
    overflow-x: auto;
}
