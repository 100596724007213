$primary-main: #263043;
// rgb(1, 32, 126)
$secondary: #376fd0;
$tertiary: #5582d4;
$quaternary: #254e92;

/* Grayscale  */
$gs-background-main: #f1f5f9;
$gs-background-lte: #fafafa;
$gs-gray-md: #4e4e4e;
$gs-white: white;

// Backgrounds:
$background-headers-lte: #516e93;
$background-headers-dark: #254e92;
$background-LTE: #fafafa;
$background-primary: #f1f5f9;

// text colors:
$text-color-primary: #504e55;
// $text-color-primary: #606264;
$text-color-dark: #606264;
$text-color-secondary: #b0b4ba;
$text-color-headers: #1e293a;
$text-color-sub-headers: #64748b;
$text-grid-primary: #504e55;
$dissabled-text-color: #adb2b4;
$dissabled-background-color: #e3e3e3;

$text-color-lte: #eeeeee;
/* Side Nav Bar */
// $nav-text-category: #738eb4;
$nav-text-category: #8aa6d0;
$nav-text-disabled: #a2a4aa;
$nav-link-container-active: #182130;

/* Accent */
$accent-success: #22c55f;
$accent-warning: #dd393c;
$accent-alert: #f59e0a;
$logo-accent-blue: #2196f3;

/* Accent */
$box-shadow-1: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
